import React, { useEffect } from 'react';
import { CommonBaseContent } from '../Layouts/CommonBackContent';
import dynamic from 'next/dynamic';
import styles from './index.module.less';
import BlogContent from './BlogContent';

const TopContent = dynamic(() => import('./TopContent'));
const ChooseContent = dynamic(() => import('./ChooseContent'));
const ReviewsContent = dynamic(() => import('./ReviewsContent'));
const FAQContent = dynamic(() => import('./FAQContent'));
const MainContent = dynamic(() => import('./MainContent'));
const HowUseContent = dynamic(() => import('./HowUseContent'));
const HairstylesCreatedContent = dynamic(() => import('./HairstylesCreatedContent'));
const OtherLanding = dynamic(() => import('./OtherLanding'));
const HairStyleLandingHome = ({ data }: { data?: any }) => {
  useEffect(() => {}, [data?.pathParam]);
  return (
    <>
      <CommonBaseContent>
        <div className={styles.content}>
          <TopContent data={data?.pathParam} pageContent={data?.pageContent} />
          <MainContent data={data} />
          <HowUseContent data={data?.pathParam} pageContent={data?.pageContent} />
          <HairstylesCreatedContent pageContent={data?.pageContent} />
          <ChooseContent data={data?.pathParam} pageContent={data?.pageContent} />
          <OtherLanding data={data?.pathParam} />
        </div>
      </CommonBaseContent>
      <ReviewsContent data={data?.pathParam} />
      <CommonBaseContent>
        <FAQContent data={data?.pathParam} pageContent={data?.pageContent} />
        {data?.pageContent?.contentInfo?.['section-top-blog']?.title && (
          <BlogContent
            title={data?.pageContent?.contentInfo?.['section-top-blog']?.title}
            data={data?.pageContent?.topBlogList}
          />
        )}
        {data?.pageContent?.contentInfo?.['section-blog']?.title && (
          <BlogContent
            title={data?.pageContent?.contentInfo?.['section-blog']?.title}
            data={data?.pageContent?.blogList}
          />
        )}
        <div style={{ display: 'none' }}>
          <h2>Hairpaca</h2>
          <h3>FEATURES</h3>
          <h3>RESOURCES</h3>
          <h3>PRODUCT</h3>
          <h3>WORK WITH US</h3>
        </div>
      </CommonBaseContent>
    </>
  );
};

export default HairStyleLandingHome;
