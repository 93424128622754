import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import Image from 'next/image';
import { imgUrl } from '@/shared/utils';
interface IBlogContentProps {
  date: string;
  isTop: boolean;
  articleId: number;
  title_id: string;
  cover_image: string;
  title: string;
}

const BlogContent = ({ title, data }: { title?: string; data?: IBlogContentProps[] }) => {
  const router = useRouter();
  const [page, setPage] = useState<number>(1);
  const pageSize = 8;

  const getDataList = useMemo(() => {
    if (data?.length > page * pageSize) {
      return data.slice(0, page * pageSize);
    } else {
      return data;
    }
  }, [data, page]);

  const getMore = useMemo(() => {
    return data?.length > page * pageSize;
  }, [data, page]);

  return (
    <div className={styles.content}>
      <h2 className={styles.sectionTitle}>{title}</h2>

      <div className={styles.blogGrid}>
        {getDataList?.map((post) => (
          <div
            key={post.title_id}
            className={styles.blogCard}
            onClick={() => router.push(`/blog/${post.title_id}`)}
          >
            <div className={styles.imageContainer}>
              <Image
                src={imgUrl(post.cover_image, 'mid')}
                alt={post.title}
                className={styles.blogImage}
                width={232}
                height={131}
                loading="lazy"
              />
            </div>
            <div className={styles.blogInfo}>
              <h3 style={{ display: 'none' }}>{post.title}</h3>
              <p className={styles.blogTitle}>{post.title}</p>
              <p className={styles.blogDate}>{post.date}</p>
            </div>
          </div>
        ))}
        <h3 style={{ display: 'none' }}>{'More blogs'}</h3>
      </div>

      {getMore && (
        <div className={styles.moreButtonContainer}>
          <button className={styles.moreButton} onClick={() => setPage(page + 1)}>
            more <span className={styles.arrowDown}>▼</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogContent;
